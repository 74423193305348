 .container-card-rubros {
    width: 100%;
    height: 250px;
    display: flex;
  }
  
  .img-container {
    width: 100%;
  }
   
  .txt-container {
    width: 30%;
    display: flex;
    align-items: center;
  }
 
  .img-fix-card-rubros {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

  
  .gradient-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

  
  .text-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
  } 

  .text-wrapper p{
    font-size: 25px;
    color: var(primary02);
  }