.ci-container{
    display: flex;
    flex-wrap: wrap;
   
}



.cardcontainer{
    display: flex;
    flex-direction: column;
    border-radius:8px ;
    padding: 10px;
    max-width: 250px;
    margin: 10px;
    margin: auto;
    margin-bottom: 10px;

}

.picture-container{
    display: flex;
    place-content: center;
    height: 200px;
}

.picture{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 4px;
}

.cardcontent{
    text-align: left;
}


.title{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    margin-top:5px ;
    max-height: 2.6em;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.btn-compra{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.price{
    margin-top:5px ;
}

.category{
    
}

.line{
    
}