.card-marca-container {
    background-color: var(primary-bg-color);
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
    overflow: hidden;
    width: 100%;
  }
  
  .card-marca-img-container {
    height: 95px;
    margin-bottom: -40px;
    overflow: hidden;
    text-align: center;
  }
  .card-marca-img-container img{
    display: inline-block;
    height: 100%;
    margin: 0 -100px;
    width: 100%;
  }
  
  .logo-overlay {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.1);
    height: 80px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 80px;
    display: flex;
    align-items: center;
}



.card-marca-title {
    color:var(primary02);
    font-size: 24px;
    font-weight: 600;
    height: 35px;
    margin: 16px 0;
    overflow: hidden;
    padding: 0 16px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.products-container {
    display: flex;
    flex-direction: column;
}
 

.shop-products{
    margin-top: 22px;
    order: 2;
    text-align: center;
}
  .products {
    height: 74px;
    order: 1;
    position: relative;
    text-align: center;
    z-index: 2;
  }

  .products-item{
    border: 1px solid #eee;
    border-radius: 8px;
    display: inline-block;
    height: 70px;
    margin: 3px;
    overflow: hidden;
    width: 70px;
  }