.ci-container{
    display: flex;
    flex-wrap: wrap;
   
}

.cardcontainer{
    display: flex;
    flex-direction: column;
    border-radius:8px ;
    padding: 10px;
    max-width: 250px;
    margin: 10px;
    margin: inherit;
    margin-bottom: 10px;
    position: relative;
}

.picture-container{
    display: flex;
    place-content: center;
    height: 200px;
    flex-direction: column;
    position: relative;
}

.picture-container .badge-container {
    list-style: none;
    position: absolute;
    top: 80%; 
    transform: translateY(-50%); 
    left: 40%; 
    transform: translateX(-50%); 
    width: 100%;
    text-align: center;
}


.picture-container ul{
    list-style: none;
    position: absolute;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}


.picture{
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 4px;
}

.cardcontent{
    text-align: left;
}


.title{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin: 0;
    margin-top:5px ;
    max-height: 2.6em;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.btn-compra{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.price{
    margin-top:5px ;
}
