
/*nuevo estilo*/
.box {
  box-sizing: border-box;
  transition: transform 0.3s;
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}

.box:hover {
  transform: scale(1.05);
}

.section-img {
  display: block;
  height: 100%;
  object-position: center;
  width: 100%;
}

.content-center {
  position: absolute;
  top: 50%;
  left: 5%; 
  transform: translateY(-50%); 
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; 
}

.icon-img {
  border-radius: 6px;
  height: 70px;
  width: 70px;
  background-color: #fff;
  margin-right: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  margin-left: -10px; 
}

.text-style {
  font-size: 18px;
  margin: 0; 
  color: #000;
}

.text-style-2 {
  font-size: medium;
  margin: 0; 
  color: #000;
}

.special-text {
  color: #fff;
  font-size: medium;
}

/*viejo estilo hasta cambio de imagenes*/
/* .container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.box {

  box-sizing: border-box;
  transition: transform 0.3s;
  border-radius: 6px;
  display: block;
  height: 250px;
  overflow: hidden;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}

.box:hover {
  transform: scale(1.05);
}

.rectangle {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.section-img {
  display: block;
  height: 100%;

  object-position: top;
  width: 100%;
}

.img-fit-contain {
  object-fit: contain;
}

.half-width-container {
  bottom: 0;
  height: 124px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.half-width {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  left: 0;
  padding: 16px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.half-width-img {
  align-self: flex-end;
  border-radius: 6px;
  display: flex;
  height: 80px;
  object-fit: cover;
  overflow: hidden;
  width: 80px;
  background-color: #fff;
  margin-right: 20px;
}

.test-section-img {
  border-radius: 50%;
  display: block;
  height: 250px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.text-style {
  color: #fff;
  font-size: 18px;
}

.text-style-2 {
  color: #fff;
  font-size: medium;
}

.circle-container {
  width: 100px; 
  height: 100px; 
  border-radius: 50%; 
  overflow: hidden;


} */

