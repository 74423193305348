/* barrabusqueda.css */

/* Estilos para el contenedor principal del componente */
.main-header-center {
    position: relative;
    width: 100%;
  }
  
  /* Estilos para el input de búsqueda */
  .react-autosuggest__input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  /* Estilos para las sugerencias */
  .react-autosuggest__suggestions-container {
    display: none;
    position: absolute;
    top: 100%;
    width: calc(100% - 2px);
    max-height: 100vh;
    overflow-y: auto;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: #fff;
    z-index: 5;

  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
  }
  
  /* Estilos para las sugerencias individuales */
  .react-autosuggest__suggestion {
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .react-autosuggest__suggestion:hover {
    background-color: #f8f9fa;
  }
  
  /* Estilos para el botón de búsqueda */
  .btn-search {
    position: absolute;
    top: 0;
    right: 0;
  }

  .custom-input {
    position: relative;
    z-index: 915;
    color: #4d5875; /* Color del texto */
    font-size: 0.875rem; /* Tamaño de la fuente */
    font-weight: 400; /* Peso de la fuente */
    line-height: 2; /* Altura de línea */
    background-color: #fff; /* Color de fondo */
    background-clip: padding-box;
    border: 1px solid #ededf5; /* Borde sólido */
    border-radius: 5px; /* Bordes redondeados */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; /* Transición */
    display: block;
    width: 100%; /* Ancho del input */
    height: 45px; /* Altura del input */
    padding: 0.375rem 0.75rem; /* Relleno */
    outline: none; /* Quita el contorno al hacer clic */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05); /* Sombra en gris claro */
}

.search-container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 0.5rem; /* Espacio adicional a la derecha para el icono */
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.search-icon {
  position: absolute;
  right: 10px; /* Coloca el ícono en el extremo derecho del input */
  top: 50%; /* Centra el ícono verticalmente */
  transform: translateY(-50%); /* Alinea verticalmente al 50% */
  font-size: 1.2rem;
  color: #666;
  cursor: pointer; /* Asegura que el icono sea clickeable */
  z-index: 999; /* Asegura que el icono esté sobre el input */
}



