.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

}

.box-circle{
    /* width: calc(33.33% - 20px); */
    /* width:80%; */
    
    /* margin: 10px;
    padding: 10px; */
    box-sizing: border-box;
    transition: transform 0.3s;
    border-radius: 6px;
    display: block;
    height: 250px;
    overflow: hidden;
    position: relative;
    text-align: center;
    margin-bottom: 10px;
}


.box:hover {
    transform: scale(1.05); 
    
}


.rectangle {
width: 100%;
padding: 10px;
box-sizing: border-box;
}

.section-img {
    display: block;
    height: 100%;
    /* object-fit: cover; */
    object-position: top;
    width: 100%;
}

.img-fit-contain{
    object-fit:contain;
}


.half-width-container{
    bottom: 0;
    height: 124px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.half-width {
        align-items: center;
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        left: 0;
        padding: 16px;
        position: absolute;
        width: 100%;
        z-index: 1;
        
}

.half-width-img{
    align-self: flex-end;
    border-radius: 6px;
    display: flex;
    height: 80px;
    object-fit: cover;
    overflow: hidden;
    width: 80px;
}

.test-section-img{
    border-radius: 50%;
    display: block;
    height: 250px;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.text-style-article{
    color: #fff;
    font-size: 18px;
}


.circle-container {
    width: 100px; /* Ancho del círculo */
    height: 100px; /* Alto del círculo */
    border-radius: 50%; /* Hace que el contenedor sea circular */
    overflow: hidden; /* Oculta partes de la imagen fuera del círculo */
}

.color-background{
    background-color: #fff;
}
