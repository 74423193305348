.fc-carrusel-container {
  display: block;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  margin: 0px;
  overflow: hidden;
width: 100% !important;
}

.fc-carrusel-item {
  
  display: flex;
  transition: transform 0.8s ease;
  position: relative;
}
/* 
.fc-slide {
  width: 14%;
  flex: 0 0 auto;
  margin-right: 20px !important; 
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
} */

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  border: 1px solid #ddd ;
  background: #fff;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%; 
  font-size: 20px;
  width: 64px;
  transition: box-shadow 0.3s;
}

.prev-button:hover,
.next-button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.fc-carousel-btn-icon{
  margin-left: 6px;
  color: #C62828;
}